@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.main-container {
  height: 80vh;
}

.image-name {
  text-align: center;
}

.next-arrow,
.pre-arrow {
  position: absolute;
  top: 40%;
  cursor: pointer;
  z-index: 2;
  padding: 10px;
  border-radius: 50%;
}

.pre-arrow {
  transform: rotate(0.5turn);
  left: -70px;
}

.next-arrow {
  right: -70px;
}

/* Responsive adjustments for arrows */
@media (max-width: 768px) {
  .pre-arrow {
    left: -30px;
    /* Bring arrows closer on smaller screens */
  }

  .next-arrow {
    right: -30px;
  }

  .next-arrow,
  .pre-arrow {
    padding: 5px;
    /* Reduce padding on small screens */
  }
}

.carousel-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.carousel-card img {
  width: 100%;
  aspect-ratio: 3/2;
  object-fit: contain;
  mix-blend-mode: color-burn;
}

/* Optional: Add scale effect for each image */
.carousel-card img:hover {
  transform: scale(1.05);
}

.text-p {
  text-align: center;
  margin: 0;
  font-size: 28px;
font-weight: bold;
  padding-bottom: 4vh;
  text-transform: uppercase;
}

/* Responsive image adjustments */
@media (max-width: 768px) {
  .carousel-card img {
    aspect-ratio: 3/2;
    /* Adjust aspect ratio for medium screens */
  }
}

@media (max-width: 480px) {
  .carousel-card img {
    aspect-ratio: 3/2;
    /* Adjust aspect ratio for small screens */
  }

  .text {
    font-size: 18px;
    /* Reduce font size for smaller screens */
  }
}

/* Ensure carousel container is flexible */
.carousel-container {
  max-width: 100%;
}

/* Padding and box sizing for carousel card */
.carousel-card {
  padding: 10px;
  box-sizing: border-box;
}

/* Responsive padding adjustments */
@media (max-width: 768px) {
  .carousel-card {
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .carousel-card {
    padding: 5px;
  }
}