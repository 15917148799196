.aboutus-section {
    min-height: auto;
    display: flex;
    align-items: center;
    padding: 0;
}

/* Container for the "Why Us?" and "Our Strengths" sections */
.info-content {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    width: 100%;
}

.section {
    flex: 1;
    margin-top: 10vh;
}

.text-a {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 1vh;
    text-align: start;
}

/* Styling for the main section titles */
.text-w,
.text-o {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
}

/* Bullet points styling */
ul {
    list-style-type: none;
    padding-left: 0;
}

ul li {
    margin-bottom: 10px;
    color: #5f5f5f;
    font-size: 16px;
}

/* Container for the entire content */
.container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 15px;
}

.content-row {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.left-content {
    flex: 1;
    padding: 1vh;
}

.left-content p {
    font-size: 17.5px;
    color: #5f5f5f;
    text-align: justify;
}

.right-content {
    flex: 1;
    padding: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.right-content img {
    width: 500px;
    height: 300px;
    object-fit: cover;
    margin-bottom: 20px;
}

/* Button container styling */
.button-container {
    margin-top: 35px;
    text-align: center;
}

.button-container button {
    color: #2a2a2a;
    border: 1px solid #2a2a2a;
    padding: 12px 24px;
    background: none;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-weight: 600;
    cursor: pointer;
}

.button-container button:hover {
    background-color: #2a2a2a;
    color: #fff;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
    .aboutus-section {
        padding: 40px 0;
        flex-direction: column-reverse;
    }

    /* Media query for mobile screens */
    @media (max-width: 768px) {
        .content-row {
            flex-direction: column-reverse;
            /* Stack content vertically */
        }

        .left-content,
        .right-content {
            width: 100%;
            /* Make both contents take full width */
            padding: 10px;
            /* Adjust padding for smaller screens */
        }
    }

    .info-content {
        flex-direction: column;
    }

    .left-content {
        text-align: center;
        order: 2;
    }

    .right-content {
        order: 1;
        width: 100%;
    }

    .right-content img {
        max-width: 100%;
        height: auto;
    }

    .left-content p {
        font-size: 14px;
    }

    .button-container button {
        padding: 10px 20px;
        font-size: 14px;
    }

    .right-content {
        margin-top: 30px;
    }
}

@media (max-width: 480px) {
    .left-content p {
        font-size: 13px;
    }

    .button-container button {
        padding: 8px 16px;
        font-size: 12px;
    }

    .right-content img {
        max-width: 100%;
    }
}