/* 
---------------------------------------------
footer
--------------------------------------------- 
*/

footer {
  background-color: #2a2a2a;
  padding: 2vh;
}

.contact-info1 {
  background-color: #2a2a2a;
}

.info-group ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  list-style-type: none;
  padding: 0;
  margin-right: -16px;
  margin-left: -10px;
}

.info-group ul li {
  padding: 1vh;
  text-align: center;
  color: #ffffff;
  margin-right: 10vw;
  margin-left: 5vw;
}

.info-group ul li a {
  color: #ffffff;
  transition: color 0.3s;
  /* Smooth transition for link color change */
}

.info-group ul li:nth-child(2) {
  text-align: center;
  /* Align phone in the center */
}

/* Under footer styling */
footer .under-footer {
  padding-top: 2vh;
  text-align: center;
  border-top: 1px solid rgba(250, 250, 250, 0.3);
}

footer .under-footer ul {
  margin-top: 20px;
}

footer .under-footer ul li {
  display: inline-block;
  margin: 0 10px;
}

footer .under-footer ul li a {
  font-size: 22px;
}

footer .under-footer p {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
}

footer .under-footer a {
  color: #cff;
}

footer .under-footer a:hover {
  color: #c66;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  footer .under-footer ul li a {
    font-size: 18px;
    /* Decrease font size for tablets */
  }

  footer .under-footer p {
    font-size: 12px;
    /* Decrease footer text size */
  }

  .info-group ul li {
    flex: 100%;
    text-align: center;
    /* Stack content and center-align */
  }

  .info-group ul li:nth-child(1),
  .info-group ul li:nth-child(2),
  .info-group ul li:nth-child(3) {
    text-align: center;
    /* Override previous alignment for smaller screens */
  }
}

@media (max-width: 480px) {
  footer {
    padding: 40px 0 20px 0;
    /* Adjust padding for mobile screens */
  }

  footer .under-footer ul li {
    margin: 0 5px;
    /* Adjust margin for list items */
  }

  footer .under-footer ul li a {
    font-size: 16px;
    /* Further decrease font size for mobile */
  }

  footer .under-footer p {
    font-size: 12px;
    /* Maintain smaller text size for mobile */
  }
}