html {
    scroll-behavior: smooth;
}

header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background: rgba(255, 255, 255, 1);
    transition: background-color 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.right-section {
    display: flex;
    align-items: center;
    gap: 10px;
}

.dropdown-menu {
    display: flex;
    gap: 10px;
    padding: 0;
    margin: 0;
}

.dropdown-menu li {
    display: flex;
    padding: 0;
}

.dropdown-menu li button {
    white-space: nowrap;
}

header.transparent {
    background: rgb(255, 255, 255);
}

.Header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 4vw;
}

.right-section img {
    max-height: 50px;
    transition: transform 0.3s;
}

.right-section img:hover {
    transform: scale(1.1);
}

.left-section {
    display: flex;
    gap: 10px;
}

.button {
    background: none;
    border: none;
    padding: 10px 20px;
    margin: 0;
    cursor: pointer;
    font-size: 16px;
    color: #5e3906ae;
    text-transform: uppercase;
    transition: color 0.3s, background-color 0.3s, transform 0.3s;
}

.button-t {
    background: none;
    border: none;
    margin: 0;
    cursor: pointer;
    text-align: center;
    font-size: 16px;
    color: #5e3906ae;
    text-transform: uppercase;
    transition: color 0.3s, background-color 0.3s, transform 0.3s;
}

.button:hover {
    color: #5e3906;
    background-color: #ffffff;
    transform: scale(1.1);
}

/* Products dropdown styling */
.products-dropdown {
    position: relative;
}

ul li button{
justify-content: center;
}

.dropdown-menu {
    display: none;
    position: absolute;
    top: 100%;
    left: -120px;
    background-color: #fff;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);

    list-style: none;
    margin: 0;
    border-radius: 4px;
    z-index: 1000;
    min-width: 200px;
    /* Adjusted width */
}

.dropdown-menu li {
    font-size: 14px;
    /* Adjusted font size */
    padding: 8px 10px;
    /* Add padding for individual items */
}

.text-h {
    text-align: start;
    margin: 0;
    font-weight: bold;
    color: #5e3906;
    padding-bottom: 0vh;
    text-decoration: none;
    text-transform: uppercase;
}

.products-dropdown:hover .dropdown-menu {
    display: block;
}

.dropdown-menu li {
    padding: 0px 0;
    cursor: pointer;
    transition: background-color 0.3s;
}

.dropdown-menu li:hover {
    background-color: #dedcdcd4;
}

.hamburger {
    display: none;
    /* Hide hamburger by default */
    flex-direction: column;
    cursor: pointer;
}

.line {
    width: 25px;
    height: 3px;
    background-color: #8e8e8e;
    margin: 3px 0;
    transition: all 0.3s ease;
}

.open {
    background-color: #000;
    /* Change color on open */
}

.mobile-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70px;
    /* Adjust based on your header height */
    right: 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 10px;
    border-radius: 4px;
}

.mobile-menu .styled-button {
    margin: 10px 0;
    /* Space between buttons */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .left-section {
        display: none;
        /* Hide original menu on small screens */
    }

    .hamburger {
        display: flex;
        /* Show hamburger on mobile */
    }
}

.hamburger {
    display: none;
    /* Hide hamburger by default */
    flex-direction: column;
    cursor: pointer;
}

.line {
    width: 25px;
    height: 3px;
    background-color: #8e8e8e;
    margin: 3px 0;
    transition: all 0.3s ease;
}

.open:nth-child(1) {
    transform: rotate(45deg);
    /* First line to rotate */
    margin: 0;
    /* Remove margin to align correctly */
}

.open:nth-child(2) {
    opacity: 0;
    /* Middle line disappears */
}

.open:nth-child(3) {
    transform: rotate(-45deg);
    /* Last line to rotate */
    margin: 0;
    /* Remove margin to align correctly */
}

.mobile-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70px;
    /* Adjust based on your header height */
    right: 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 10px;
    border-radius: 4px;
}

.mobile-menu .styled-button {
    margin: 10px 0;
    /* Space between buttons */
}


/* Responsive Styles */
@media (max-width: 768px) {
    .left-section {
        display: none;
        /* Hide original menu on small screens */
    }

    .txta {
        text-decoration: none;
        color: #000;
    }

    .styled-button {
        text-transform: uppercase;
    }

    .hamburger {
        display: flex;
        /* Show hamburger on mobile */
    }
}

.hamburger {
    display: none;
    /* Hide hamburger by default */
    flex-direction: column;
    cursor: pointer;
}

.line {
    width: 25px;
    /* Width of each line */
    height: 3px;
    /* Height of each line */
    background-color: #8e8e8e;
    /* Initial line color */
    margin: 4px 0;
    /* Space between lines */
    transition: all 0.3s ease;
    /* Smooth transition for transformations */
}

/* Style for the lines when the menu is open */
.open {
    background-color: #000;
    /* Change color to black on open */
}

/* Transform first line to the top left */
.open:nth-child(1) {
    transform: translateY(7px) rotate(45deg);
    /* Move down and rotate */
}

/* Hide the middle line */
.open:nth-child(2) {
    opacity: 0;
    /* Hide middle line */
    transform: scale(0);
    /* Optionally scale it down */
}

/* Transform the last line to the bottom left */
.open:nth-child(3) {
    transform: translateY(-7px) rotate(-45deg);
    /* Move up and rotate */
}

.mobile-menu {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 70px;
    /* Adjust based on your header height */
    right: 0;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    padding: 10px;
    border-radius: 4px;
}

.mobile-menu .styled-button {
    margin: 10px 0;
    /* Space between buttons */
}

/* Responsive Styles */
@media (max-width: 768px) {
    .left-section {
        display: none;
        /* Hide original menu on small screens */
    }

    .hamburger {
        display: flex;
        /* Show hamburger on mobile */
    }
}