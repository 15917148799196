/* 
---------------------------------------------
Contact Page Styles
--------------------------------------------- 
*/

.contact-section {
  height: auto;
  margin-top: 2vh;
}

.form-group textarea {
  width: 100%;
  height: 170px;
  padding: 10px;
  font-size: 14px;
  font-style: italic;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
}

ul li a {
  font-size: 14px;
  color: #000000;
  transition: all 0.3s;
  display: block;
  margin-bottom: 0px;
}

ul li a:hover {
  text-decoration: none;
  color: #000;
}

.contact-page {
  margin-top: 0px;
  padding: 0px;
}

.container {
  max-width: 98vw;
  margin: 0 auto 2vh;
}

.text-c {
  font-weight: bold;
  color: #333;
  text-align: center;
  text-transform: uppercase;
  font-size: 28px;
  margin-bottom: 4vh;
}

.contact-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 30px;
}

.contact-form,
.contact-info {
  flex: 1 1 45%;
  background-color: #fff;
  padding: 1vh;
  border-radius: 8px;
}

.contact-form .section-heading p {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group input {
  width: 100%;
  height: 44px;
  padding: 0 15px;
  font-size: 14px;
  font-style: italic;
  color: #333;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group button {
  width: 100%;
  height: 44px;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.form-group button:hover {
  background-color: #333;
}

.contact-info .info-group {
  margin-bottom: 20px;
}

.contact-info ul li {
  margin-bottom: 15px;
  font-size: 16px;
  color: #333;
}

.contact-info ul li span,
.contact-info ul li span a {
  font-size: 14px;
  font-weight: 400;
  color: #666;
}

.contact-info ul li span a:hover {
  color: #000;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .contact-content {
    flex-direction: column;
    /* Stack elements vertically */
  }

  .contact-form,
  .contact-info {
    flex: 1 1 100%;
    /* Each section takes full width on smaller screens */
  }
}

@media (max-width: 480px) {
  .contact-page {
    padding: 10px;
    /* Decrease page padding for smaller screens */
  }

  .container {
    max-width: 100%;
    /* Ensure the container takes full width */
    padding: 0 15px;
  }

  .contact-form,
  .contact-info {
    padding: 15px;
    /* Reduced padding for smaller screens */
    max-width: 100%;
    /* Ensure full width of container */
    margin: 0 auto;
  }

  h1 {
    font-size: 24px;
    /* Adjusted font size for smaller headers */
  }

  .form-group input,
  .form-group textarea {
    font-size: 12px;
    /* Reduced input font size */
  }

  .form-group button {
    height: 40px;
    /* Adjusted button height for smaller screens */
  }

  .contact-info ul li {
    font-size: 14px;
    /* Reduced font size for list items */
  }

  /* Added width limitation for smaller screens */
  .contact-section,
  .contact-content {
    max-width: 400px;
    /* Set a max width for mobile */
    margin: 0 auto;
    /* Center the content */
  }
}