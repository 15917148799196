@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

.main-container {
  padding: o;
  height: auto;
}

/* Adjust navigation arrow positions for smaller screens */
.next-arrow,
.prev-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;
  padding: 0;
}

.carousel-card-d img {
  width: 100%;
  aspect-ratio: 1/1.2;
  object-fit: contain;
  mix-blend-mode: color-burn;
}
.prev-arrow {
  left: -50px;
}

.next-arrow {
  right: -50px;
}

/* Responsive adjustments for arrows */
@media (max-width: 768px) {
  .prev-arrow {
    left: -20px;
    /* Bring arrows closer on small screens */
  }
  .carousel-card-d img {
    width: 100%;
    aspect-ratio: 3/4;
    object-fit: contain;
    mix-blend-mode: color-burn;
  }
  .next-arrow {
    right: -20px;
  }
}

.carousel-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.carousel-card {
  transition: transform 0.3s ease-in-out;
}

.carousel-card:hover {
  transform: translateY(-10px);
}

/* Typography adjustments for smaller screens */
.carousel-card h3 {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

@media (max-width: 768px) {
  .carousel-card h3 {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .carousel-card h3 {
    font-size: 14px;
  }
}

.carousel-card ul {
  list-style: none;
  padding: 0;
}

.carousel-card li {
  font-size: 14px;
  color: #000000;
}

@media (max-width: 768px) {
  .carousel-card li {
    font-size: 12px;
  }
}

.text-d {
  text-align: center;
  color: #333;
  display: flex;
  font-weight: bold;
  margin-bottom: 2vh;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-size: 28px;

}

@media (max-width: 768px) {
  .text {
    font-size: 24px;
    text-align: center;
  }
}

@media (max-width: 480px) {
  .text {
    font-size: 20px;
    text-align: center;
  }
}

/* Ensure carousel container is flexible */
.carousel-container {
  max-width: 100%;
}